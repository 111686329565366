<i18n>
{
  "en": {
    "browser_title": "Free Sample"
  },
  "ja": {
    "browser_title": "無料サンプル"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />

    <hero-image event-category="Free Sample Page" />

    <div class="contents">
      <sample-list :num-movies="18" />
      <section class="section divider">
        <event-list event-category="Free Sample Page" />
      </section>
      <section class="section" v-if="showActresses">
        <actresses-list custom-list-id="1591830141479-a00902e1-d7d7" @actressesEnabled="actressesEnabled" />
      </section>
    </div>
  </main>
</template>

<script>
/* eslint max-len: 0 */
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import HeroImage from '@/components/home/heroImage.vue';
import EventList from '@/components/home/eventList.vue';
import SampleList from '@/components/freeSample/sampleList.vue';
import ActressesList from '@/components/freeSample/actressesList.vue';

export default {
  props: {
    numMovies: Number,
  },
  components: {
    'vue-headful': vueHeadful,
    'hero-image': HeroImage,
    'sample-list': SampleList,
    'event-list': EventList,
    'actresses-list': ActressesList,
  },
  data() {
    return {
      dynamicTitle: '',
      showActresses: true,
      movieList: [],
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  methods: {
    actressesEnabled(value) {
      this.showActresses = value;
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_home.scss';
</style>
