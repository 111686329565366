<i18n>
{
  "en": {
    "header": "Popular Actresses",
    "view_more": "View more"
  },
  "ja": {
    "header": "人気AV女優",
    "view_more": "もっと見る"
  }
}
</i18n>

<template>
  <div>
    <h2>{{ $t('header') }}</h2>
    <div class="actress-list" id="freesample--actress-list">
      <div class="flex-grid">
        <div
          class="grid-item"
          v-for="actress in actressList"
          :key="actress.id"
          @click="clickTrack('Free Sample Page', 'Popular Actress', actress.name.ja)"
        >
          <router-link :to="`/search/?a=${actress.id}`" class="entry">
            <div class="entry-media">
              <div class="media-thum">
                <img class="media-thum-image" :src="`/dyn/dla/images/actresses/portraits/${actress.id}/1pon__@200.jpg`" loading="lazy" alt="fpo" v-sfw />
              </div>
            </div>
            <div class="entry-meta">
              <div class="meta-title">{{ actress.name.ja }}</div>
              <div class="meta-data">{{ actress.name.en }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="button-box button-box--center">
      <router-link to="/actress/">
        <button
          @click="clickTrack('Free Sample Page', 'Popular Actress', 'View More')"
          class="button-ghost button-default button-large button-icon--right"
        >
          {{ $t('view_more') }}
          <svg class="tm-icon">
            <use xlink:href="#tm-icon-arrow-right" />
          </svg>
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
/* eslint max-len: 0 */
import CustomLists from '@/assets/js/services/CustomLists';

export default {
  props: {
    customListId: String,
  },
  data() {
    return {
      actressList: [],
    };
  },
  async created() {
    const customListSvc = new CustomLists();
    this.actressList = await customListSvc.getList('actresses', this.customListId);

    if (!this.actressList) this.$emit('actressesEnabled', false);
  },
  methods: {
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
