<i18n>
{
  "en": {
    "events_header": "Featured Events",
    "more_info": "More Info"
  },
  "ja": {
    "events_header": "特集/イベント",
    "more_info": "詳細をみる"
  }
}
</i18n>

<template>
<div class="content-primary-side mb-24">
  <h2>{{ $t('events_header') }}</h2>
  <div class="event">
    <div class="flex-grid">

      <!-- loop thru event banners -->
      <div class="grid-item" v-for="(banners, groupName) in badminBannerGroups" :key="groupName">
        <div v-for="banner in banners" :key="banner.display_order">
          <div class="entry">
            <div class="entry-media" @click="clickTrack('Top Page', 'Event Banner', banner.google_analytics_label);">
              <!-- image: opens in new window -->
              <div class="media-thum" v-if="!banner.pathname || banner.url_target === true">
                <a
                  :href="banner.url"
                  :data-event-title="banner.title"
                  target="_blank"
                  rel="noopener">
                  <img class="media-thum-image" :src="`/dyn/dla/images/${banner.img_path}`" v-sfw>
                </a>
              </div>
              <!-- image: goes to route -->
              <div class="media-thum" v-if="banner.pathname">
                <router-link
                  :to="banner.pathname"
                  :data-event-title="banner.title">
                  <img class="media-thum-image" :src="`/dyn/dla/images/${banner.img_path}`" v-sfw>
                </router-link>
              </div>
            </div>
            <div class="entry-meta">
              <h3 class="event-title">{{ banner.title }}</h3>
              <div class="event-description">{{ banner.body }}</div>
              <!-- more info button: opens in new window -->
              <div
                v-if="(banner.title || banner.body) && (!banner.pathname || banner.url_target === true)"
                @click="clickTrack('Top Page', 'Event Banner', banner.google_analytics_label);"
                class="event-description">
                <a
                  :href="banner.url"
                  :data-event-title="banner.title"
                  target="_blank"
                  rel="noopener">
                  {{ $t('more_info') }}<i class="iconf-keyboard-arrow-right is-right"></i>
                </a>
              </div>
              <!-- more info button: goes to route -->
              <div
                v-if="(banner.title || banner.body) && banner.pathname"
                @click="clickTrack('Top Page', 'Event Banner', banner.google_analytics_label);"
                class="event-description">
                <router-link
                  :to="banner.pathname"
                  :data-event-title="banner.title">
                  {{ $t('more_info') }}
                  <i class="iconf-keyboard-arrow-right is-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-unneeded-ternary: 0 */

import Badmin from '@/assets/js/services/Badmin';

export default {
  props: {
    // this component is also used in /freesample/, but it needs its own GA Event Category, so
    // setting up an optional prop for it
    eventCategory: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      badminBannerGroups: {},
    };
  },
  created() {
    const badminSvc = new Badmin(this.$i18n.locale, this.$store.getters['user/typeID']);
    badminSvc.getBanners('event').then((banners) => {
      this.badminBannerGroups = banners;

      // hide section header in the home page if there are no event banners
      this.$emit('eventsEval', (Object.keys(this.badminBannerGroups).length === 0) ? false : true);
    });
  },
  computed: {
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  methods: {
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(this.eventCategory || category, action, label, value);
    },
  },
  watch: {
    userTypeID(newVal, oldVal) {
      if (newVal !== oldVal) {
        const badminSvc = new Badmin(this.$i18n.locale, this.$store.getters['user/typeID']);
        badminSvc.getBanners('event').then((banners) => {
          this.badminBannerGroups = banners;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
