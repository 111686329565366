<i18n>
{
  "en": {
    "heading": "Free Sample Videos"
  },
  "ja": {
    "heading": "無料サンプル動画"
  }
}
</i18n>

<template>
  <div>
    <div class="section">
      <div class="heading">
        <h1>{{ $t('heading') }}</h1>
      </div>
      <p v-if="locale =='ja'">完全無料で楽しめる無修正アダルト動画がズラリ！一本道で話題の作品から人気作品まで、すべて無料で見放題！</p>
    </div>
    <div class="section divider">
      <div class="list list--default list--card">
        <div class="flex-grid">
          <!-- movie item -->
          <movie-thumbnail
            v-for="movie in movieList"
            :key="movie.MovieID"
            :movie="movie"
            theme="freesample-newest" />
          <!-- /movie item -->
        </div>
      </div>
      <!-- /.list -->
    </div>
  </div>
</template>

<script>
/* eslint max-len: 0 */
import MovieHelper from '@/assets/js/utils/movie';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import movieThumbnail from '@/components/_common/movieThumbnail.vue';

export default {
  props: {
    numMovies: {
      type: Number,
      default: 9,
    },
  },
  components: {
    'movie-thumbnail': movieThumbnail,
  },
  data() {
    return {
      movieList: [],
    };
  },
  created() {
    this.getMovies(this.userTypeID);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  methods: {
    async getMovies(userTypeID) {
      const bfAPI = new BifrostAPI();

      const result = await bfAPI.getMovieListRandom(0, this.numMovies, 'newest');
      const movieList = MovieHelper.expandDetailsList(
        result.Rows,
        this.locale,
        userTypeID,
      );
      this.movieList = MovieHelper.filterExcludedMovieList(
        movieList,
        userTypeID,
      );
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getMovies(newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
